.header {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-fluid {
  width: 100%;
  padding: 0 40px;
}


.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 60px;
}

.nav {
  flex: 1;
  margin: 0 4rem;
}

.nav-list {
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 3rem;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-link:hover {
  color: #3B82F6;
}

.dropdown {
  position: relative;
}

.arrow {
  font-size: 0.7rem;
  margin-left: 0.3rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0;
  padding: 8px 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  min-width: 200px;
  z-index: 1000;
  list-style: none;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  cursor: pointer;
}

.flag-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .nav {
    margin: 0 2rem;
  }

  .nav-list {
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0.5rem 0;
  }

  .logo img {
    height: 45px;
  }
  .nav {
    display: none; /* 在移动端需要添加汉堡菜单 */
  }
  .mobile-dropdown.active .mobile-dropdown-menu {
    list-style: none;
  }
}

/* 移动端菜单按钮 */
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.menu-toggle .bar {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.menu-toggle.active .bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.menu-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active .bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* 移动端样式 */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav {
    display: none; /* 默认隐藏导航 */
    position: fixed;
    top: 80px; /* header 高度 */
    left: 0;
    right: 0;
    background: #000;
    padding: 20px 0;
  }

  .nav.active {
    display: block;
  }

  .nav-list {
    flex-direction: column;
    padding: 0 20px;
  }

  .nav-list > li {
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav-list > li:last-child {
    border-bottom: none;
  }

  .nav-link {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* 移动端下拉菜单样式 */
  .dropdown .dropdown-menu {
    display: none;
    position: static;
    background: transparent;
    box-shadow: none;
    padding-left: 20px;
  }

  .dropdown.active .dropdown-menu {
    display: block;
  }

  .dropdown-menu .dropdown-item {
    color: white;
    padding: 12px 0;
    opacity: 0.8;
  }

  .arrow {
    display: inline-block;
    margin-left: 8px;
    transition: transform 0.3s ease;
  }

  .dropdown.active .arrow {
    transform: rotate(180deg);
  }

  /* 确保 logo 在移动端正确显示 */
  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .logo {
    position: relative;
    z-index: 1001;
  }

  /* 隐藏 PC 端的下拉菜单交互 */
  .dropdown .nav-link:hover + .dropdown-menu,
  .dropdown .dropdown-menu:hover {
    display: none;
  }
}

/* PC端导航 */
.desktop-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}
.logo {
  display: flex;
  align-items: center;
}
.nav-list {
  display: flex;
  gap: 30px;
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

/* 移动端导航 - 默认隐藏 */
.mobile-nav {
  display: none;
}

.nav-link {
  color: #eee;
  text-decoration: none;
  padding: 8px 16px;
  position: relative;
  transition: color 0.3s;
}

/* 激活状态的样式 */
.nav-link.active {
  color: #fff; /* 使用你的主题色 */
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 16px;
  right: 16px;
  height: 2px;
  background-color: #fff;
}

/* 下拉菜单项的激活状态 */
.dropdown-item.active {
  color: #3B82F6;
  background-color: rgba(128, 128, 128, 0.1);
}

/* 移动端激活状态 */
.mobile-nav-list .nav-link.active {
  color: #3B82F6;
}

.mobile-dropdown.active > .nav-link {
  color: #3B82F6;
}

@media (max-width: 820px) {
  .nav-wrapper {
    gap: 10px;
  }
  .nav-link {
    font-size: 0.8rem;
  }
}

/* 移动端样式 */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1001;
  }

  .menu-toggle .bar {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
  }

  .menu-toggle.active .bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .menu-toggle.active .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.active .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    background: #000;
    padding: 20px 0;
  }

  .mobile-menu.active {
    display: block;
  }

  .mobile-nav-list {
    list-style: none;
    padding: 0 20px;
    margin: 0;
  }

  .mobile-nav-list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .mobile-nav-list li:last-child {
    border-bottom: none;
  }

  .mobile-nav-list .nav-link {
    padding: 15px 0;
    display: block;
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }

  .mobile-nav-list .dropdown-menu {
    padding-left: 20px;
  }

  .mobile-nav-list .dropdown-item {
    color: white;
    opacity: 0.8;
    padding: 12px 0;
    display: block;
    text-decoration: none;
  }

  .mobile-dropdown .nav-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .mobile-dropdown .arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
  }

  .mobile-dropdown.active .arrow {
    transform: rotate(180deg);
  }

  .mobile-dropdown-menu {
    display: none;
    padding-left: 20px;
  }

  .mobile-dropdown.active .mobile-dropdown-menu {
    display: block;
  }

  .mobile-dropdown-menu .dropdown-item {
    color: white;
    opacity: 0.8;
    padding: 12px 0;
    display: block;
    text-decoration: none;
  }

  .mobile-dropdown-menu .dropdown-item:hover {
    opacity: 1;
  }
} 