.footer {
  background: #f8f9fa;
  padding: 60px 0 30px;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin-bottom: 40px;
}
/* .footer-content .footer-section:nth-child(1) {
  padding-right: 20px;
} */
.footer-content .footer-section:nth-child(1) >p {
  padding-right: 50px;
}
.footer-content .footer-section:nth-child(2) {
  padding-left: 20px;
}

.footer-section h3 {
  color: #1E293B;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.footer-section p {
  color: #64748B;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 0.75rem;
}

.footer-section ul li a {
  color: #64748B;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #3B82F6;
}

.footer-bottom {
  padding-top: 30px;
  border-top: 1px solid #e2e8f0;
  text-align: center;
}

.footer-bottom p {
  color: #94A3B8;
  font-size: 0.875rem;
}

/* 响应式设计 */
@media (max-width: 992px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
@media (max-width: 912px) {
  .footer-content .footer-section,
  .footer-content .footer-section:nth-child(2) {
    padding-left: 6rem;
  }
}

@media (max-width: 820px) {
  .footer-content .footer-section,
  .footer-content .footer-section:nth-child(2) {
    padding-left: 5rem;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 40px 0 20px;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section h3 {
    margin-bottom: 1rem;
  }

  .footer-section ul li {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 540px) {
  .footer-content .footer-section,
  .footer-content .footer-section:nth-child(2) {
    padding-left: 0;
  }
  .footer-content .footer-section:nth-child(1) >p {
    padding-left: 2rem;
    padding-right: 1.5rem;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .footer-content .footer-section,
  .footer-content .footer-section:nth-child(2) {
    padding-left: 0;
  }
 
  .footer-content .footer-section:nth-child(1) >p {
    padding-left: 2rem;
    padding-right: 1.5rem;
    text-align: left;
  }
  .footer-content .footer-section:nth-child(2) {
    padding-left: 0;
  }
}
